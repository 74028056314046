import {
  TClauseTextAlternative,
  InclusionRequirement,
  TClauseTemplate,
  TPlaybook,
  TPlaybookEditHistory,
  PlaybookHistoryChangeType,
  TSavedClauseTemplate,
  TSavedClauseTextAlternative,
  TSavedPlaybook,
  TSavedPlaybookCheck,
  TPlaybookCheck,
  TSavedManagedCheckSuite,
  TCheck,
  TClauseTemplateCheck,
  TSavedClauseTemplateCheck,
  TExampleSuggestion,
  TUser,
  UserRole,
  CheckScope,
  TInvite,
  TPlaybookTestContract,
  TCheckTestRun,
  CheckTestRunState,
  CheckTestRunResult,
  CheckTestRunResultState,
  TClauseCheckResultBenchmarkExample,
  TContractCheckResultBenchmarkExample,
  TContractCheckFixBenchmarkExample,
} from "./PlaybookTypes";
import { toLowerCaseSafe } from "../utils/toLowerCaseSafe";
import { PreferenceLevel } from "../components/library/LegacyPreferenceLevel";
import { Maybe } from "./Types";
import { DefaultUserRole, TOrganization } from "./OrganizationsTypes";
import { getCurrentTextFromDiff } from "@/utils/checkResultsUtils";

export enum DiffChangeType {
  no_change = "no_change",
  insertion = "insertion",
  deletion = "deletion",
  insertion_and_deletion = "insertion_and_deletion",
}

export type GetPlaybooksAPIResponse = {
  id: string;
  name: string;
  description: string;
  managed_check_suites: GetManagedCheckSuiteAPIResponse[];
  created_by: GetUserAPIResponse;
  created_at: string;
  updated_at: string;
  use_clause_checks: boolean;
};

export type GetClauseAPIResponse = {
  id: string;
  playbook_id: string;
  name: string;
  inclusion_requirement: string;
  text: string;
  guidance: string;
  guidance_version: number;
  notes: string;
  clause_text_alternatives: ApiResponseClauseTextAlternative[];
  checks: GetClauseTemplateCheckAPIResponse[];
};

export type GetCheckAPIResponse = {
  id: string;
  name: string;
  text: string;
  notes: string;
  resolution_guidance: string;
  comments_enabled: boolean;
  comment_guidance: string;
  scope: string;
  created_at: string;
  updated_at: string;
  disabled_at?: Maybe<string>;
  playbook_id?: string;
  clause_template_id?: string;
};

export type GetExampleSuggestionsAPIResponse = {
  id: string;
  check_id: string;
  original_text: string;
  suggested_text: string;
  suggested_comment: string;
  created_at: string;
  created_by: GetUserAPIResponse;
};

export type GetUserAPIResponse = {
  id: string;
  microsoft_id: string;
  name: string;
  email: string;
  role?: string;
  created_at: string;
  updated_at: string;
};

export type GetInviteAPIResponse = {
  id: string;
  email: string;
  role: string;
  created_at: string;
  updated_at: string;
};

export type GetPlaybookCheckAPIResponse = GetCheckAPIResponse & {
  playbook_id: string;
};

export type GetClauseTemplateCheckAPIResponse = GetPlaybookCheckAPIResponse & {
  clause_template_id: string;
};

export type GetManagedCheckSuiteAPIResponse = {
  id: string;
  name: string;
  description: string;
  published_at: Maybe<string>;
};

export type CreatePlaybookCheckAPIRequestBody = {
  playbook_id: string;
  name: string;
  text: string;
  notes: string;
  resolution_guidance: string;
  comments_enabled: boolean;
  comment_guidance: string;
  scope: string;
};

export type CreateClauseTemplateCheckAPIRequestBody = {
  playbook_id: string;
  name: string;
  text: string;
  notes: string;
  resolution_guidance: string;
  comment_guidance: string;
  clause_template_id: string;
};

export type CreateCheckAPIRequestBody = {
  name: string;
  text: string;
  notes: string;
  resolution_guidance: string;
  comment_guidance: string;
  comments_enabled: boolean;
  playbook_id?: string;
  clause_template_id?: string;
  scope?: string;
};

export type UpdateCheckAPIRequestBody = {
  name: string;
  text: string;
  notes: string;
  resolution_guidance?: string;
  comments_enabled?: boolean;
  comment_guidance?: string;
  scope?: string;
  clause_template_id?: Maybe<string>;
};

export type UpdateManagedCheckSuiteAPIRequestBody = {
  name: string;
  description: string;
};

export type UpdateClauseAPIRequestBody = {
  name: string;
  inclusion_requirement: string;
  notes: string;
  text: string;
};

export type CreateClauseAPIRequestBody = {
  playbook_id: string;
  name: string;
  inclusion_requirement: string;
  text: string;
  guidance: string;
  notes?: string;
};

export type UpdatePlaybookMetadataAPIRequestBody = {
  name: string;
  description: string;
  use_clause_checks: boolean;
};

export type CreatePlaybookAPIRequestBody = {
  name: string;
  description: string;
  use_clause_checks: boolean;
};

export type AddManagedCheckSuiteToPlaybookAPIRequestBody = {
  managed_check_suite_id: string;
};

export type GetPlaybookEditHistoryAPIResponse = {
  change_type: string;
  clause_name: string;
  clause_template_id: string;
  clause_text_alternative_id: string;
  check_name: string;
  check_id: string;
  user: GetUserAPIResponse;
  created_at: string;
  changed_fields: {
    field_name: string;
    old_value: string;
    new_value: string;
  }[];
};

export type GetOrganizationAPIResponse = {
  id: string;
  name: string;
  is_personal: boolean;
  default_role_for_new_users: string;
  created_at: string;
  updated_at: string;
  trial_end_date: Maybe<string>;
  disabled_at: Maybe<string>;
  is_pincites: Maybe<boolean>;
};

export type ApiResponseClauseTextAlternative = {
  id: string;
  clause_template_id: string;
  text: string;
  preference_level: string;
  comment: string;
};

export type GetPlaybookTestContractsAPIResponse = {
  id: string;
  name: string;
  playbook_id: string;
  created_at: string;
  updated_at: string;
};

export type CreatePlaybookTestContractAPIRequestBody = {
  playbook_id: string;
  file: File;
};

export type SubmitFeedbackAPIRequestBody = {
  subject: string;
  message: string;
};

export type TTrackedChangeDiff = {
  change_type: DiffChangeType;
  text: string;
};

export type GetCheckTestRunAPIResponse = {
  id: string;
  state: CheckTestRunState;
  results: {
    contract: GetPlaybookTestContractsAPIResponse;
    matched_clause?: TTrackedChangeDiff[] | null;
    check_test_run_id: string;
    state: CheckTestRunResultState;
    result: CheckTestRunResult | null;
    explanation: string | null;
    error_generating_suggestions: string | null;
    sources_with_tracked_changes:
      | {
          change_type: DiffChangeType;
          text: string;
        }[][]
      | null;
    suggestions:
      | null
      | {
          id: string;
          original_text_with_tracked_changes: {
            change_type: DiffChangeType;
            text: string;
          }[];
          suggested_text: string;
          suggested_comment: string;
        }[];
  }[];
  created_at: string;
  updated_at: string;
};

export type CreateCheckTestRunAPIRequestBody = {
  playbook_id: string;
  check_clause_template_id?: string;
  check_scope: string;
  check_text: string;
  check_resolution_guidance: string;
  check_comments_enabled: boolean;
  check_comment_guidance: string;
};

export type CreateClauseCheckResultBenchmarkExampleApiRequestBody = {
  check_text: string;
  example_name: string;
  clause_template_id: string;
  document_clause_text: string;
  expected_result: CheckTestRunResult;
  expected_sources: string[];
};

export type CreateContractCheckResultBenchmarkExampleApiRequestBody = {
  test_contract_id: string;
  check_text: string;
  example_name: string;
  expected_result: CheckTestRunResult;
  expected_sources: string[];
};

export type CreateContractCheckFixBenchmarkExampleApiRequestBody = {
  example_name: string;
  check_text: string;
  check_resolution_guidance: string;
  check_comment_guidance: string;
  check_failure_reason: string;
  test_contract_id: string;
  check_source_clauses: string[];
  expected_language: string;
  expected_suggestion_content: string[];
  expected_comment_content: string[];
  expected_suggestion_content_omissions: string[];
  expected_comment_content_omissions: string[];
};

export function getClauseFromGetClauseAPIResponse(response: GetClauseAPIResponse): TSavedClauseTemplate {
  const {
    name,
    text,
    guidance,
    guidance_version,
    notes,
    inclusion_requirement,
    clause_text_alternatives,
    playbook_id,
    id,
    checks,
  } = response;

  const mappedClauseTextAlternatives: TClauseTextAlternative[] = clause_text_alternatives.map(
    (item: ApiResponseClauseTextAlternative) => ({
      clauseID: item.clause_template_id,
      text: item.text,
      preference_level: item.preference_level as PreferenceLevel,
      comment: item.comment,
      alternativeLanguageID: item.id,
    }),
  );

  const clauseChecks: TClauseTemplateCheck[] = checks.map((check) => getClauseTemplateCheckFromCheckAPIResponse(check));

  return {
    checks: clauseChecks,
    clauseID: id,
    playbookID: playbook_id,
    name,
    inclusion_requirement:
      InclusionRequirement[inclusion_requirement.toUpperCase() as keyof typeof InclusionRequirement],
    text,
    guidance,
    guidance_version,
    notes,
    clause_text_alternatives: mappedClauseTextAlternatives,
  };
}

export function transformClauseToApiRequest(clause: TClauseTemplate): CreateClauseAPIRequestBody {
  const transformedClause = {
    playbook_id: clause.playbookID as string,
    name: clause.name,
    inclusion_requirement: toLowerCaseSafe(clause.inclusion_requirement),
    text: clause.text,
    guidance: clause.guidance,
    notes: clause.notes,
  };

  return transformedClause;
}

export function getCheckFromCheckAPIResponse(response: GetCheckAPIResponse) {
  const {
    name,
    text,
    notes,
    resolution_guidance,
    comments_enabled,
    comment_guidance,
    id,
    scope,
    created_at,
    updated_at,
    disabled_at,
    playbook_id,
    clause_template_id,
  } = response;

  return {
    checkID: id,
    name,
    text,
    notes,
    scope: scope as CheckScope,
    resolutionGuidance: resolution_guidance,
    commentsEnabled: comments_enabled,
    commentGuidance: comment_guidance,
    createdAt: created_at,
    updatedAt: updated_at,
    disabledAt: disabled_at,
    playbookID: playbook_id,
    clauseTemplateID: clause_template_id,
  };
}

export function getExampleFromExampleAPIResponse(response: GetExampleSuggestionsAPIResponse): TExampleSuggestion {
  const { id, check_id, original_text, suggested_text, suggested_comment, created_at, created_by } = response;

  return {
    exampleID: id,
    checkID: check_id,
    originalText: original_text,
    suggestedText: suggested_text,
    suggestedComment: suggested_comment,
    createdAt: created_at,
    createdBy: getUserFromAPIResponse(created_by),
  };
}

export function getUserFromAPIResponse(response: GetUserAPIResponse): TUser {
  return {
    id: response.id,
    microsoftID: response.microsoft_id,
    name: response.name,
    email: response.email,
    role: (response.role as UserRole) ?? undefined,
    createdAt: response.created_at,
    updatedAt: response.updated_at,
  };
}

export function getInviteFromAPIResponse(response: GetInviteAPIResponse): TInvite {
  return {
    id: response.id,
    email: response.email,
    role: response.role as UserRole,
    createdAt: response.created_at,
    updatedAt: response.updated_at,
  };
}

export function getPlaybookCheckFromCheckAPIResponse(response: GetPlaybookCheckAPIResponse): TSavedPlaybookCheck {
  const {
    name,
    text,
    notes,
    resolution_guidance,
    comments_enabled,
    comment_guidance,
    scope,
    id,
    created_at,
    updated_at,
    disabled_at,
    playbook_id,
    clause_template_id,
  } = response;

  return {
    checkID: id,
    playbookID: playbook_id,
    name,
    text,
    notes,
    scope: scope as CheckScope,
    resolutionGuidance: resolution_guidance,
    commentsEnabled: comments_enabled,
    commentGuidance: comment_guidance,
    createdAt: created_at,
    updatedAt: updated_at,
    disabledAt: disabled_at,
    clauseTemplateID: clause_template_id,
  };
}

export function getClauseTemplateCheckFromCheckAPIResponse(
  response: GetClauseTemplateCheckAPIResponse,
): TSavedClauseTemplateCheck {
  const {
    name,
    text,
    notes,
    scope,
    resolution_guidance,
    comments_enabled,
    comment_guidance,
    playbook_id,
    clause_template_id,
    id,
    created_at,
    updated_at,
    disabled_at,
  } = response;

  return {
    checkID: id,
    playbookID: playbook_id,
    name,
    text,
    notes,
    scope: scope as CheckScope,
    resolutionGuidance: resolution_guidance,
    commentsEnabled: comments_enabled,
    commentGuidance: comment_guidance,
    createdAt: created_at,
    updatedAt: updated_at,
    disabledAt: disabled_at,
    clauseTemplateID: clause_template_id,
  };
}

export function getManagedCheckSuiteFromAPIResponse(
  response: GetManagedCheckSuiteAPIResponse,
): TSavedManagedCheckSuite {
  const { name, description, id, published_at } = response;

  return {
    checkSuiteID: id,
    name,
    description,
    publishedAt: published_at,
  };
}

export function transformPlaybookCheckToApiRequest(check: TPlaybookCheck): CreatePlaybookCheckAPIRequestBody {
  const transformedCheck = {
    playbook_id: check.playbookID,
    name: check.name,
    text: check.text,
    notes: check.notes,
    resolution_guidance: check.resolutionGuidance,
    comments_enabled: check.commentsEnabled,
    comment_guidance: check.commentGuidance,
    scope: check.scope,
  };

  return transformedCheck;
}

export function transformClauseTemplateCheckToApiRequest(
  check: TClauseTemplateCheck,
): CreateClauseTemplateCheckAPIRequestBody {
  const transformedCheck = {
    playbook_id: check.playbookID,
    name: check.name,
    text: check.text,
    notes: check.notes,
    resolution_guidance: check.resolutionGuidance,
    comment_guidance: check.commentGuidance,
    clause_template_id: check.clauseTemplateID,
  };

  return transformedCheck;
}

export function transformCheckToApiCreationRequest(check: TCheck): CreateCheckAPIRequestBody {
  const transformedCheck = {
    name: check.name,
    text: check.text,
    notes: check.notes,
    resolution_guidance: check.resolutionGuidance,
    comments_enabled: check.commentsEnabled,
    comment_guidance: check.commentGuidance,
    ...(check.scope && { scope: check.scope }),
    ...(check.playbookID && { playbook_id: check.playbookID }),
    ...(check.clauseTemplateID && {
      clause_template_id: check.clauseTemplateID,
    }),
  };

  return transformedCheck;
}

export function transformCheckToApiRequest(check: TCheck): CreateCheckAPIRequestBody {
  const transformedCheck = {
    name: check.name,
    text: check.text,
    notes: check.notes,
    comments_enabled: check.commentsEnabled,
    resolution_guidance: check.resolutionGuidance,
    comment_guidance: check.commentGuidance,
    ...(check.scope && { scope: check.scope }),
  };

  return transformedCheck;
}

export function transformClauseCheckResultBenchmarkExampleToApiRequest(
  example: TClauseCheckResultBenchmarkExample,
): CreateClauseCheckResultBenchmarkExampleApiRequestBody {
  const transformedExample = {
    check_text: example.checkText,
    example_name: example.exampleName,
    clause_template_id: example.clauseTemplateID,
    document_clause_text: example.documentClauseText,
    expected_result: example.expectedResult,
    expected_sources: example.expectedSources,
  };

  return transformedExample;
}

export function transformContractCheckResultBenchmarkExampleToApiRequest(
  example: TContractCheckResultBenchmarkExample,
): CreateContractCheckResultBenchmarkExampleApiRequestBody {
  const transformedExample = {
    test_contract_id: example.testContractID,
    check_text: example.checkText,
    example_name: example.exampleName,
    expected_result: example.expectedResult,
    expected_sources: example.expectedSources,
  };

  return transformedExample;
}

export function transformContractCheckFixBenchmarkExampleToApiRequest(
  example: TContractCheckFixBenchmarkExample,
): CreateContractCheckFixBenchmarkExampleApiRequestBody {
  const transformedExample = {
    example_name: example.exampleName,
    check_text: example.checkText,
    check_resolution_guidance: example.checkResolutionGuidance,
    check_comment_guidance: example.checkCommentGuidance,
    check_failure_reason: example.checkFailureReason,
    test_contract_id: example.testContractID,
    check_source_clauses: example.checkSourceClauses,
    expected_language: "en",
    expected_suggestion_content: example.expectedSuggestionContent,
    expected_comment_content: example.expectedCommentContent,
    expected_suggestion_content_omissions: example.expectedSuggestionContentOmissions,
    expected_comment_content_omissions: example.expectedCommentContentOmissions,
  };

  return transformedExample;
}

export function getPlaybookFromGetPlaybookAPIResponse(playbook: GetPlaybooksAPIResponse): TSavedPlaybook {
  return {
    playbookID: playbook.id,
    metadata: {
      name: playbook.name,
      description: playbook.description,
    },
    updatedAt: playbook.updated_at,
    createdBy: getUserFromAPIResponse(playbook.created_by),
    managedCheckSuites: (playbook.managed_check_suites ?? []).map((mcs) => getManagedCheckSuiteFromAPIResponse(mcs)),
    useClauseChecks: playbook.use_clause_checks,
  };
}

export function transformPlaybookToApiRequest(playbook: TPlaybook) {
  const transformedPlaybook = {
    name: playbook.metadata.name,
    description: playbook.metadata.description,
    use_clause_checks: playbook.useClauseChecks,
  };

  return transformedPlaybook;
}

export function getPlaybookHistoryFromAPIResponse(
  response: GetPlaybookEditHistoryAPIResponse[],
): TPlaybookEditHistory[] {
  return response.map((history) => {
    return {
      changeType: history.change_type as PlaybookHistoryChangeType,
      clauseName: history.clause_name,
      clauseTemplateID: history.clause_template_id,
      clauseTextAlternativeID: history.clause_text_alternative_id,
      checkName: history.check_name,
      checkID: history.check_id,
      user: getUserFromAPIResponse(history.user),
      createdAt: history.created_at,
      changedFields: (history.changed_fields ?? []).map((field) => {
        return {
          fieldName: field.field_name,
          oldValue: field.old_value,
          newValue: field.new_value,
        };
      }),
    };
  });
}

export function getAlternativeLanguageFromAPIResponse(
  response: ApiResponseClauseTextAlternative,
): TSavedClauseTextAlternative {
  return {
    preference_level: response.preference_level as PreferenceLevel,
    text: response.text,
    comment: response.comment,
    alternativeLanguageID: response.id,
    clauseID: response.clause_template_id,
  };
}

export function getOrganizationFromAPIResponse(response: GetOrganizationAPIResponse): TOrganization {
  return {
    id: response.id,
    name: response.name,
    isPersonal: response.is_personal,
    defaultRoleForNewUsers: response.default_role_for_new_users as DefaultUserRole,
    createdAt: response.created_at,
    updatedAt: response.updated_at,
    trialEndDate: response.trial_end_date,
    disabledAt: response.disabled_at,
    isPincites: response.is_pincites,
  };
}

export function getPlaybookTestContractsFromAPIResponse(
  response: GetPlaybookTestContractsAPIResponse,
): TPlaybookTestContract {
  return {
    id: response.id,
    name: response.name,
    playbookID: response.playbook_id,
    createdAt: response.created_at,
    updatedAt: response.updated_at,
  };
}

export function getCheckTestRunFromAPIResponse(response: GetCheckTestRunAPIResponse): TCheckTestRun {
  return {
    id: response.id,
    state: response.state,
    results: response.results.map((result) => ({
      contract: getPlaybookTestContractsFromAPIResponse(result.contract),
      checkTestRunID: result.check_test_run_id,
      matchedClause: result.matched_clause ?? [],
      state: result.state,
      result: result.result,
      explanation: result.explanation,
      errorGeneratingSuggestions: result.error_generating_suggestions,
      sources: result.sources_with_tracked_changes?.map((source) => getCurrentTextFromDiff(source)) ?? null,
      suggestions: result.suggestions
        ? result.suggestions.map((suggestion) => ({
            id: suggestion.id,
            originalText: getCurrentTextFromDiff(suggestion.original_text_with_tracked_changes),
            suggestedText: suggestion.suggested_text,
            suggestedComment: suggestion.suggested_comment,
          }))
        : null,
    })),
    createdAt: response.created_at,
    updatedAt: response.updated_at,
  };
}
